import { ref, watch, computed } from '@vue/composition-api';
import { title } from '@core/utils/filter';
import moment from 'moment';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';

export default function useTemplatesList() {
  // Use toast
  const toast = useToast();

  const refTemplateListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Name', sortable: true, stickyColumn: true, variant: 'primary' },
    { key: 'fundingSources', label: 'Funding Sources', sortable: true },
    { key: 'parent', label: 'Parent', sortable: false },
    { key: 'default', label: 'Default?', sortable: true },
    { key: 'automatic', label: 'Automatic?', sortable: true },
    { key: 'status', sortable: true },
    { key: 'createdAt', label: 'Created', sortable: true },
    { key: 'actions' },
  ];

  const perPage = ref(10);
  const totalTemplates = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('createdAt');
  const isSortDirDesc = ref(true);

  const fundingSourceFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refTemplateListTable.value ? refTemplateListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTemplates.value,
    };
  });

  const refetchData = () => {
    refTemplateListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, fundingSourceFilter, statusFilter], () => {
    refetchData();
  });

  const fetchTemplates = (ctx, callback) => {
    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    const pl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sb,

      populate: 'pathway session',
    };

    if (fundingSourceFilter.value) {
      pl.fundingSource = fundingSourceFilter.value;
    }
    if (statusFilter.value) {
      pl.status = statusFilter.value;
    }

    store
      .dispatch('receivableStoreModule/fetchTemplates', pl)
      .then((response) => {
        const { results, totalResults } = response.data;

        callback(results);
        totalTemplates.value = totalResults;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching templates list-pending',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // archived, internal, public

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const statusOptions = [
    { label: 'Created', value: 'created' },
    { label: 'Active', value: 'active' },
    { label: 'Archived', value: 'archived' },
  ];

  const dateScheduleRelative = [
    { label: 'After', value: 'after' },
    { label: 'Before', value: 'before' },
    // { label: 'Absolute', value: 'absolute' },
  ];

  const dateScheduleType = [
    { label: 'Start Date', value: 'start_date' },
    { label: 'End Date', value: 'end_date' },
  ];

  const dateScheduleUnit = [
    { label: 'Day', value: 'day' },
    { label: 'Week', value: 'week' },
    { label: 'Month', value: 'month' },
    { label: 'Year', value: 'year' },
  ];

  return {
    fetchTemplates,
    tableColumns,
    perPage,
    currentPage,
    totalTemplates,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTemplateListTable,

    refetchData,

    statusOptions,

    dateScheduleRelative,
    dateScheduleType,
    dateScheduleUnit,

    // Extra Filters
    fundingSourceFilter,
    statusFilter,
  };
}
